import React from "react";
import { Routes, Route, Link } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  Box,
  IconButton,
  useTheme,
} from "@mui/material";
import { Brightness4, Brightness7 } from "@mui/icons-material";
import ErrorBoundary from "./components/ErrorBoundary";
import InputList from "./components/InputList";
import EventList from "./components/EventList";
import ComponentShowcase from "./components/library/ComponentShowcase";
import { NotificationSystem } from "./components/common/NotificationSystem";
import { useApp } from "./contexts/AppContext";

function App() {
  const { darkMode, toggleDarkMode } = useApp();
  const theme = useTheme();

  return (
    <ErrorBoundary>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Event Tracking System
            </Typography>
            <Box sx={{ "& > :not(style)": { m: 1 } }}>
              <Link to="/" style={{ color: "white", textDecoration: "none" }}>
                Events
              </Link>
              <Link
                to="/inputs"
                style={{ color: "white", textDecoration: "none" }}
              >
                Inputs
              </Link>
              <Link
                to="/components"
                style={{ color: "white", textDecoration: "none" }}
              >
                Components
              </Link>
              <IconButton
                sx={{ ml: 1 }}
                onClick={toggleDarkMode}
                color="inherit"
              >
                {darkMode ? <Brightness7 /> : <Brightness4 />}
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
        <Container maxWidth="xl" sx={{ mt: 4 }}>
          <Routes>
            <Route path="/" element={<EventList />} />
            <Route path="/inputs" element={<InputList />} />
            <Route path="/components" element={<ComponentShowcase />} />
          </Routes>
        </Container>
        <NotificationSystem />
      </Box>
    </ErrorBoundary>
  );
}

export default App;
