import { useState } from 'react';

// Delay utility function
export const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

export const useLoadingWithCountdown = (initialLoadingState = false) => {
  const [loading, setLoading] = useState(initialLoadingState);

  const startLoading = () => {
    setLoading(true);
  };

  const stopLoading = () => {
    setLoading(false);
  };

  return { loading, startLoading, stopLoading };
};
