import React from 'react';
import {
  Card as MuiCard,
  CardContent,
  CardHeader,
  CardActions,
  Divider,
  Box,
} from '@mui/material';

const Card = ({
  title,
  subtitle,
  headerAction,
  children,
  actions,
  elevation = 1,
  sx = {},
}) => {
  return (
    <MuiCard elevation={elevation} sx={sx}>
      {(title || subtitle || headerAction) && (
        <>
          <CardHeader
            title={title}
            subheader={subtitle}
            action={headerAction}
          />
          <Divider />
        </>
      )}
      <CardContent>
        <Box>{children}</Box>
      </CardContent>
      {actions && (
        <>
          <Divider />
          <CardActions>{actions}</CardActions>
        </>
      )}
    </MuiCard>
  );
};

export default Card;
