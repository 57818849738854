import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Suspense } from 'react';
import { LoadingOverlay } from '../components/common/LoadingOverlay';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 2,
      staleTime: 30000,
      cacheTime: 10 * 60 * 1000,
      refetchOnMount: 'if-stale',
      suspense: false,
      useErrorBoundary: true,
    },
    mutations: {
      useErrorBoundary: true,
      retry: 1,
    },
  },
});

export function QueryProvider({ children }) {
  return (
    <QueryClientProvider client={queryClient}>
      <Suspense fallback={<LoadingOverlay />}>
        {children}
      </Suspense>
      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
    </QueryClientProvider>
  );
}
