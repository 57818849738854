import React from 'react';
import { Alert, Snackbar, Stack } from '@mui/material';
import { useApp } from '../../contexts/AppContext';

export function NotificationSystem() {
  const { notifications, removeNotification } = useApp();

  return (
    <Stack spacing={2} sx={{ position: 'fixed', bottom: 24, right: 24, zIndex: 2000 }}>
      {notifications.map(({ id, message, type }) => (
        <Snackbar key={id} open={true} onClose={() => removeNotification(id)}>
          <Alert onClose={() => removeNotification(id)} severity={type} sx={{ width: '100%' }}>
            {message}
          </Alert>
        </Snackbar>
      ))}
    </Stack>
  );
}
