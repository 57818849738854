import React from 'react';
import { Chip } from '@mui/material';

const statusColors = {
  success: 'success',
  error: 'error',
  warning: 'warning',
  info: 'info',
  default: 'default',
};

const StatusBadge = ({
  status,
  color = 'default',
  variant = 'filled',
  size = 'medium',
  ...props
}) => {
  const chipColor = statusColors[color] || color;

  return (
    <Chip
      label={status}
      color={chipColor}
      variant={variant}
      size={size}
      {...props}
    />
  );
};

export default StatusBadge;
