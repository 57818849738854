import React from 'react';
import { Box, Typography } from '@mui/material';

const DiffView = ({ oldText, newText }) => {
  const findDifferences = (text1, text2) => {
    const words1 = text1.split(/(\s+)/);
    const words2 = text2.split(/(\s+)/);
    const result = [];
    let i = 0;
    let j = 0;
    while (i < words1.length || j < words2.length) {
      if (i >= words1.length) {
        result.push({ value: words2[j], added: true });
        j++;
      } else if (j >= words2.length) {
        result.push({ value: words1[i], removed: true });
        i++;
      } else if (words1[i] === words2[j]) {
        result.push({ value: words1[i] });
        i++;
        j++;
      } else {
        result.push({ value: words1[i], removed: true });
        result.push({ value: words2[j], added: true });
        i++;
        j++;
      }
    }
    return result;
  };

  const differences = findDifferences(oldText || '', newText || '');

  return (
    <Box sx={{ 
      fontFamily: 'monospace',
      whiteSpace: 'pre-wrap',
    }}>
      {differences.map((part, index) => (
        <Typography
          key={index}
          component="span"
          variant="body2"
          sx={{
            ...(part.added && {
              bgcolor: 'success.light',
              color: 'success.dark',
            }),
            ...(part.removed && {
              bgcolor: 'error.light',
              color: 'error.dark',
              textDecoration: 'line-through',
            }),
          }}
        >
          {part.value}
        </Typography>
      ))}
    </Box>
  );
};

export default DiffView;
