import React, { createContext, useContext, useState, useCallback } from 'react';

const AppContext = createContext();

const defaultFilters = {
  events: {
    reaction: null,
    issue: null,
    status: null,
    date: null,
    country: null,
  },
  inputs: {
    type: null,
    category: null,
    status: null,
  },
  has_comments: null,
};

const defaultPreferences = {
  darkMode: true,
  tablePageSize: 25,
  dateFormat: 'MMM DD, YYYY HH:mm:ss',
  expandedSections: new Set(),
};

export function AppProvider({ children }) {
  // Theme preferences
  const [darkMode, setDarkMode] = useState(defaultPreferences.darkMode);
  
  // User preferences
  const [preferences, setPreferences] = useState(defaultPreferences);
  
  // Filters state
  const [filters, setFilters] = useState(defaultFilters);
  
  // UI State
  const [selectedItems, setSelectedItems] = useState(new Set());
  const [expandedSections, setExpandedSections] = useState(new Set());
  
  // Loading and error states
  const [globalLoading, setGlobalLoading] = useState(false);
  const [globalError, setGlobalError] = useState(null);
  
  // Notifications
  const [notifications, setNotifications] = useState([]);

  // Handlers
  const toggleDarkMode = useCallback(() => {
    setDarkMode(prev => !prev);
  }, []);

  const updatePreference = useCallback((key, value) => {
    setPreferences(prev => ({
      ...prev,
      [key]: value,
    }));
  }, []);

  const updateFilters = useCallback((listType, newFilters) => {
    setFilters(prev => ({
      ...prev,
      [listType]: {
        ...prev[listType],
        ...newFilters,
      },
    }));
  }, []);

  const toggleSelectedItem = useCallback((itemId) => {
    setSelectedItems(prev => {
      const newSet = new Set(prev);
      if (newSet.has(itemId)) {
        newSet.delete(itemId);
      } else {
        newSet.add(itemId);
      }
      return newSet;
    });
  }, []);

  const toggleExpandedSection = useCallback((sectionId) => {
    setExpandedSections(prev => {
      const newSet = new Set(prev);
      if (newSet.has(sectionId)) {
        newSet.delete(sectionId);
      } else {
        newSet.add(sectionId);
      }
      return newSet;
    });
  }, []);

  const addNotification = useCallback((message, type = 'info') => {
    const id = Date.now();
    setNotifications(prev => [...prev, { id, message, type }]);
    setTimeout(() => {
      removeNotification(id);
    }, 5000);
  }, []);

  const removeNotification = useCallback((id) => {
    setNotifications(prev => prev.filter(notification => notification.id !== id));
  }, []);

  const clearError = useCallback(() => {
    setGlobalError(null);
  }, []);

  const value = {
    // Theme
    darkMode,
    toggleDarkMode,
    
    // Preferences
    preferences,
    updatePreference,
    
    // Filters
    filters,
    updateFilters,
    
    // UI State
    selectedItems,
    toggleSelectedItem,
    expandedSections,
    toggleExpandedSection,
    
    // Loading and Error
    globalLoading,
    setGlobalLoading,
    globalError,
    setGlobalError,
    clearError,
    
    // Notifications
    notifications,
    addNotification,
    removeNotification,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

export function useApp() {
  const context = useContext(AppContext);
  if (context === undefined) {
    throw new Error('useApp must be used within an AppProvider');
  }
  return context;
}
