import React from 'react';
import { TextField as MuiTextField, InputAdornment } from '@mui/material';

const TextField = ({
  label,
  error,
  helperText,
  startAdornment,
  endAdornment,
  size = 'medium',
  fullWidth = true,
  required = false,
  disabled = false,
  multiline = false,
  rows,
  value,
  onChange,
  ...props
}) => {
  return (
    <MuiTextField
      label={label}
      error={!!error}
      helperText={error || helperText}
      size={size}
      fullWidth={fullWidth}
      required={required}
      disabled={disabled}
      multiline={multiline}
      rows={rows}
      value={value}
      onChange={onChange}
      InputProps={{
        startAdornment: startAdornment && (
          <InputAdornment position="start">{startAdornment}</InputAdornment>
        ),
        endAdornment: endAdornment && (
          <InputAdornment position="end">{endAdornment}</InputAdornment>
        ),
      }}
      {...props}
    />
  );
};

export default TextField;
