import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Pagination,
  Box,
  Alert,
  Button,
  TextField,
  Stack,
  Chip,
  Divider,
  IconButton,
  Collapse
} from '@mui/material';
import { useEvents, useUpdateEventFeedback } from '../hooks/useQueries';
import { useLoadingWithCountdown } from '../utils/loadingUtils';
import { LoadingOverlay } from './common/LoadingOverlay';
import CopyButton from './common/CopyButton';
import { useApp } from '../contexts/AppContext';
import DiffView from './ChangesView';
import GitBranchIcon from '@mui/icons-material/AccountTree';
import { v4 as uuidv4 } from 'uuid';
import Cookies from 'js-cookie';

const reactions = [
  { emoji: '😍', rating: 5 },
  { emoji: '🙂', rating: 4 },
  { emoji: '😐', rating: 3 },
  { emoji: '😕', rating: 2 },
  { emoji: '😡', rating: 1 }
];

const issues = [
  { key: 'past_date', label: 'past date' },
  { key: 'need_improve_date', label: 'need improve date' },
  { key: 'hard_to_verify', label: 'hard to verify' },
  { key: 'too_broad', label: 'too broad' },
  { key: 'irrelevant', label: 'irrelevant' }
];

function EventList() {
  const [userId, setUserId] = React.useState(null);
  const [page, setPage] = React.useState(1);
  const [editingCommentId, setEditingCommentId] = React.useState(null);
  const [commentText, setCommentText] = React.useState('');
  const [expandedChanges, setExpandedChanges] = React.useState(new Set());
  const [loadingEventIds, setLoadingEventIds] = React.useState(new Set());
  const { filters, updateFilters } = useApp();
  
  
  // Update the useEffect to use the setter
  React.useEffect(() => {
    let id = Cookies.get('user_id');
    if (!id) {
      id = uuidv4();
      Cookies.set('user_id', id, { expires: 365 });
    }
    setUserId(id); // Use the setter to update the state
  }, []);
  

  const { loading, countdown, startLoading, stopLoading } = useLoadingWithCountdown(true);
  const { data, error, isLoading } = useEvents(page, filters.events);
  const updateEventFeedback = useUpdateEventFeedback();

  const handleFilterChange = (filterType, value) => {
    if (filterType === 'reactions') {
      const currentReactions = filters.events.reactions || [];
      const updatedReactions = currentReactions.includes(value)
        ? currentReactions.filter(r => r !== value)
        : [...currentReactions, value];
      
      updateFilters('events', { reactions: updatedReactions });
    } else if (filterType === 'issues') {
      const currentIssues = filters.events.issues || [];
      const updatedIssues = currentIssues.includes(value)
        ? currentIssues.filter(i => i !== value)
        : [...currentIssues, value];
      
      updateFilters('events', { issues: updatedIssues });
    } else if (filterType === 'changes') {
      const currentChanges = filters.events.changes || [];
      const updatedChanges = currentChanges.includes('changes')
        ? []
        : ['changes'];
      
      updateFilters('events', { changes: updatedChanges });
    } else if (filterType === 'has_comments') {
      const currentHasComments = filters.events.has_comments;
      const updatedHasComments = !currentHasComments;
      updateFilters('events', { has_comments: updatedHasComments });
    }
    setPage(1); // Reset to first page when filters change
  };

  const toggleChanges = (eventId) => {
    setExpandedChanges(prev => {
      const newSet = new Set(prev);
      if (newSet.has(eventId)) {
        newSet.delete(eventId);
      } else {
        newSet.add(eventId);
      }
      return newSet;
    });
  };

  const clearFilters = () => {
    updateFilters('events', {
      reactions: [],
      issues: [],
      changes: [],
      has_comments: false
    });
    setPage(1);
  };

  React.useEffect(() => {
    if (isLoading) {
      startLoading();
    } else {
      stopLoading();
    }
  }, [isLoading]);

  const handleReactionClick = async (event, rating) => {
    try {
      setLoadingEventIds(prev => new Set([...prev, event.event_id]));
      await updateEventFeedback.mutateAsync({
        eventId: event.event_id,
        data: {
          user_id: userId,
          rating
        }
      });
    } catch (error) {
      console.error('Failed to update reaction:', error);
    } finally {
      setLoadingEventIds(prev => {
        const next = new Set(prev);
        next.delete(event.event_id);
        return next;
      });
    }
  };

  const handleIssueClick = async (event, issueKey) => {
    try {
      setLoadingEventIds(prev => new Set([...prev, event.event_id]));
      const currentIssues = event.feedback?.issues || {};
      const updatedIssuesList = Object.keys(currentIssues).reduce((acc, key) => {
        const userVoted = currentIssues[key]?.includes(userId);
        if (userVoted && key !== issueKey) {
          acc.push(key);
        }
        return acc;
      }, []);
    
      if (!currentIssues[issueKey]?.includes(userId)) {
        updatedIssuesList.push(issueKey);
      }
    
      await updateEventFeedback.mutateAsync({
        eventId: event.event_id,
        data: {
          user_id: userId,
          issues: updatedIssuesList
        }
      });
    } catch (error) {
      console.error('Failed to update issues:', error);
    } finally {
      setLoadingEventIds(prev => {
        const next = new Set(prev);
        next.delete(event.event_id);
        return next;
      });
    }
  };
  

  const handleCommentSave = async (event) => {
    try {
      await updateEventFeedback.mutateAsync({
        eventId: event.event_id,
        data: {
          user_id: userId,
          comment: commentText?.trim() || ''
        }
      });
  
      setEditingCommentId(null);
      setCommentText('');
    } catch (error) {
      console.error('Failed to save comment:', error);
    }
  };
  

  const formatDate = (timestamp) => {
    if (!timestamp) return '-';
    if (typeof timestamp === 'string' && timestamp.includes('T')) {
      return timestamp.split('T')[0];
    }
    try {
      const date = new Date(timestamp * 1000);
      return new Intl.DateTimeFormat('default', {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        timeZoneName: 'short'
      }).format(date);
    } catch (e) {
      return timestamp
    }
  };

  if (error) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  return (
    <>
      <Box sx={{ mb: 3 }}>
        <Stack direction="row" spacing={2} alignItems="center" flexWrap="wrap" sx={{ mb: 2 }}>
          {/* Reactions Filter */}
          {reactions.map(({ emoji, rating }) => (
            <Button
              key={rating}
              variant={(filters.events.reactions || []).includes(rating) ? 'contained' : 'outlined'}
              size="small"
              onClick={() => handleFilterChange('reactions', rating)}
            >
              {emoji}
            </Button>
          ))}
          
          <Divider orientation="vertical" flexItem />
          
          {/* Issues Filter */}
          {issues.map(({ key, label }) => (
            <Button
              key={key}
              variant={(filters.events.issues || []).includes(key) ? 'contained' : 'outlined'}
              size="small"
              onClick={() => handleFilterChange('issues', key)}
            >
              {label}
            </Button>
          ))}
          
          <Divider orientation="vertical" flexItem />
          
          {/* Changes Filter */}
          <Button
            variant={(filters.events.changes || []).includes('changes') ? 'contained' : 'outlined'}
            size="small"
            onClick={() => handleFilterChange('changes', 'changes')}
          >
            Has Changes
          </Button>

          {/* Comments Filter */}
          <Button
              variant={filters.events.has_comments ? 'contained' : 'outlined'}
              size="small"
              onClick={() => handleFilterChange('has_comments')}
          >
            Has Comments
          </Button>

          {(
            (filters.events.reactions && filters.events.reactions.length > 0) || 
            (filters.events.issues && filters.events.issues.length > 0) ||
            (filters.events.changes && filters.events.changes.length > 0) ||
            (filters.events.has_comments === true)
          ) && (
            <>
              <Divider orientation="vertical" flexItem />
              <Button
                variant="outlined"
                size="small"
                onClick={clearFilters}
              >
                Clear All Filters
              </Button>
            </>
          )}
        </Stack>

        <Typography variant="body2">
          Total: {data?.total || 0} events
        </Typography>
      </Box>

      <TableContainer component={Paper} sx={{ position: 'relative' }}>
        {loading && <LoadingOverlay countdown={countdown} />}
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ verticalAlign: 'middle' }}>ID</TableCell>
              <TableCell sx={{ verticalAlign: 'middle' }}>Title</TableCell>
              <TableCell sx={{ verticalAlign: 'middle' }}>Description</TableCell>
              <TableCell sx={{ verticalAlign: 'middle' }}>🌍</TableCell>
              <TableCell sx={{ verticalAlign: 'middle' }}>Created&nbsp;At</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.events.map((event) => (
              <React.Fragment key={event.event_id}>
                <TableRow
                  className={event.feedback?.rating === 5 ? 'row-loved' : event.feedback?.rating <= 2 ? 'row-negative' : ''}
                >
                  {/* Icons: Copy, Versions */}
                  <TableCell className={event.feedback?.rating <= 2 ? 'low-rating' : ''} sx={{ verticalAlign: 'top' }}>
                    <Stack spacing={1}>
                      <CopyButton text={event.event_id} />
                      <IconButton
                        size="small"
                        disabled={!event.changes}
                        onClick={() => toggleChanges(event.event_id)}
                        sx={{
                          opacity: event.changes ? 1 : 0.3,
                          color: expandedChanges.has(event.event_id) ? 'primary.main' : 'inherit'
                        }}
                      >
                        <GitBranchIcon />
                      </IconButton>
                    </Stack>
                  </TableCell>
                  {/* Icons: Title, Rating */}
                  <TableCell sx={{ verticalAlign: 'top' }}>
                    <Typography variant="body2">{event.title || '-'}</Typography>
                    <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
                      {reactions.map(({ emoji, rating }) => {
                        return (
                        <Button
                          key={rating}
                          variant={event.feedback?.rating?.[rating] ? 'contained' : 'outlined'}
                          color={event.feedback?.rating?.[rating]?.includes(userId) ? 'primary' : 
                            event.feedback?.ratings?.[rating]?.length > 0 ? 'secondary' : 'inherit'}
                          size="small"
                          onClick={() => handleReactionClick(event, rating)}
                          disabled={loadingEventIds.has(event.event_id)}
                        >
                          {emoji} ({event.feedback?.rating?.[rating]?.length || 0})
                        </Button>
                      )})}
                    </Stack>
                  </TableCell>
                  {/* Description, Issues,Comment */}
                  <TableCell sx={{ verticalAlign: 'top' }}>
                    <Typography variant="body2">{event.description || '-'}</Typography>
                    <Stack direction="row" sx={{ mt: 1, flexWrap: 'wrap', gap: 1  }}>
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => {
                          setEditingCommentId(event.event_id);
                          setCommentText(event.feedback?.comments?.[userId] || '');
                        }}
                        disabled={loadingEventIds.has(event.event_id)}
                      >
                        📝
                      </Button>
                      {issues.map(({ key, label }) => (
                        <Button
                          key={key}
                          variant={event.feedback?.issues?.hasOwnProperty(key) ? 'contained' : 'outlined'}
                          color={event.feedback?.issues?.[key]?.includes(userId) ? 'primary' : 
                            event.feedback?.issues?.[key]?.length > 0 ? 'secondary' : 'inherit'}
                          size="small"
                          onClick={() => handleIssueClick(event, key)}
                          disabled={loadingEventIds.has(event.event_id)}
                        >
                          {label} ({event.feedback?.issues?.[key]?.length || 0})
                        </Button>
                      ))}
                    </Stack>
                    <Box sx={{ mt: 2 }}>
                      {editingCommentId === event.event_id ? (
                        <Box sx={{ mt: 1 }}>
                          <TextField
                            fullWidth
                            multiline
                            rows={3}
                            value={commentText}
                            onChange={(e) => setCommentText(e.target.value)}
                            size="small"
                          />
                          <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
                            <Button
                              variant="contained"
                              size="small"
                              onClick={() => handleCommentSave(event)}
                            >
                              Save
                            </Button>
                            <Button
                              variant="outlined"
                              size="small"
                              onClick={() => setEditingCommentId(null)}
                            >
                              Cancel
                            </Button>
                          </Stack>
                        </Box>
                      ) : (
                        <>
                          {event.feedback?.comments && Object.entries(event.feedback.comments).map(([userId, comment]) => (
                            <Typography
                              key={userId}
                              variant="body2"
                              sx={{
                                whiteSpace: 'pre-wrap',
                                fontStyle: 'italic',
                                borderLeft: '2px solid grey',
                                color: 'rgba(255, 255, 255, 0.8)',
                                p: 1,
                                mb: 1
                              }}
                            >
                              {comment}
                            </Typography>
                          ))}
                        </>
                      )}
                    </Box>
                  </TableCell>
                  {/* Country Code */}
                  <TableCell sx={{ verticalAlign: 'top' }}>{event.country || '-'}</TableCell>
                  {/* Created At */}
                  <TableCell sx={{ verticalAlign: 'top' }}>{formatDate(event.created_at)}</TableCell>
                </TableRow>
                {event.changes && expandedChanges.has(event.event_id) && (
                  event.changes.map((change, id) => (
                    <React.Fragment key={`${change.id}-fragment`}>
                      <TableRow key={`${change.id}-row1`} sx={{ borderLeft: '2px dashed' }}>
                        <TableCell rowSpan={2}>{event.changes.length-id}</TableCell>
                        {/* Title */}
                        <TableCell sx={{ verticalAlign: 'top' }}>
                          <DiffView
                            oldText={change.title_old}
                            newText={change.title_new}
                          />
                        </TableCell>
                        {/* Description */}
                        <TableCell sx={{ verticalAlign: 'top' }}>
                          <DiffView
                            oldText={change.description_old}
                            newText={change.description_new}
                          />
                        </TableCell>
                        {/* Date */}
                        <TableCell></TableCell>
                        <TableCell sx={{ verticalAlign: 'top' }}>
                          <DiffView
                            oldText={change.date_old}
                            newText={change.date_new}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow key={`${change.id}-row2`} sx={{ borderLeft: '2px dashed' }}>
                        <TableCell colSpan={4}>
                          <b>{change.prompt_name}: </b>
                          {change.scratchpad.split('\n').map((line, index) => (
                            <React.Fragment key={index}>
                              {line}
                              {index < change.scratchpad.split('\n').length - 1 && <br />}
                            </React.Fragment>
                          ))}
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))
                )}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
        <Pagination
          count={data?.pages || 0}
          page={page}
          onChange={(_, value) => setPage(value)}
          color="primary"
        />
      </Box>
    </>
  );
}

export default EventList;
